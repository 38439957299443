import * as Collapsible from '@radix-ui/react-collapsible';
import { Root as Separator } from '@radix-ui/react-separator';
import { Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { NS } from '../../../../models/I18n';
import { Task, TaskDocument } from '../../../../models/UserServerData';
import Button from '../../../../shared/components/buttons/Button';
import { cn } from '../../../../shared/utils/cn';
import { Radio } from '../../../user-dashboard/new-components/inputs/Radio';
import { AutoScanBadge } from '../../../user-dashboard/views/applications/components/TaskListCardFile';
import Link from '../../../../shared/components/links/Link';
import { StageDetail } from '../../../../models/testing/Stage';

function File({
  fileConfig,
  isConditional,
  conditions,
  setConditions,
}: {
  fileConfig: TaskDocument;
  isConditional: boolean;
  conditions: Record<string, boolean | undefined>;
  setConditions: React.Dispatch<React.SetStateAction<Record<string, boolean | undefined>>>;
}) {
  const { t } = useTranslation(NS.UserDashboard);
  const {
    types,
    required,
    name,
    label,
    description,
    condition,
    is_auto_scan_available: isAutoScanAvailable,
    auto_scan_doc_type_id: autoScanDocTypeId,
    auto_scan_doc_type_name: autoScanDocTypeName,
  } = fileConfig;
  const radioValue = conditions[name];

  return (
    <>
      {isConditional && (
        <div className="mt-8 lg:mx-auto lg:max-w-[1480px] 3xl:px-4">
          <div className="font-semibold leading-tight text-gray-600">
            {condition?.question}
            <span className="text-red-600">* </span>
          </div>
          {condition?.description && <div className="mt-2 text-gray-600">{condition?.description}</div>}
          <div className="mt-4 space-x-6">
            <Radio
              id={`${name}-yes`}
              label={t('common.yes')}
              name={`${name}-answer`}
              checked={radioValue === true}
              onChange={() => setConditions((prevCond) => ({ ...prevCond, [name]: true }))}
              className={cn('rounded-full px-2 py-1.5', {
                'bg-brand-blue-light !text-brand-blue': radioValue === true,
              })}
            />
            <Radio
              id={`${name}-no`}
              label={t('common.no')}
              name={`${name}-answer`}
              checked={radioValue === false}
              onChange={() => setConditions((prevCond) => ({ ...prevCond, [name]: false }))}
              className={cn('rounded-full px-2 py-1.5', {
                'bg-brand-blue-light !text-brand-blue': radioValue === false,
              })}
            />
          </div>
        </div>
      )}
      {(!isConditional || (isConditional && radioValue === condition?.answer)) && (
        <li className="my-8 flex flex-col lg:mx-auto lg:max-w-[1400px] lg:flex-row lg:space-x-5 lg:px-4 3xl:px-0">
          <div className="relative mb-8 flex-1 lg:mb-0">
            <div className="flex items-start">
              <span className="font-semibold leading-tight text-gray-600">
                {label}
                {required ? (
                  <span className="ml-1 text-[12px] text-gray-500">({t(`${NS.Shared}:superform.required`)})</span>
                ) : null}
              </span>
            </div>
            <AutoScanBadge isAutoScanAvailable={isAutoScanAvailable} />
            <div className="prose prose-sm prose-slate mt-4 prose-p:my-1 prose-a:text-slate-600">
              <div dangerouslySetInnerHTML={{ __html: description }} className="text-slate-500" />
            </div>
            <span className="my-4 flex space-x-1 text-gray-600">
              {types.map((type) => (
                <code className="rounded-xl bg-gray-200 px-1 py-0.5 text-xs font-medium text-gray-700" key={type}>
                  {type}
                </code>
              ))}
            </span>
          </div>
        </li>
      )}
      <Separator decorative className="mt-8 border border-gray-100 last-of-type:hidden lg:mx-auto lg:max-w-[1480px]" />
    </>
  );
}

export function TaskListCardFile({ task, stage }: { task: Task; stage?: StageDetail }) {
  const { state } = useLocation();
  const { id, name, description, kind } = task;
  const [open, setOpen] = useState(false);
  const files = task.using_documents ? task.ordered_documents : (task.config.files as TaskDocument[]);
  const sorted = files.reduce<{
    required: TaskDocument[];
    conditional: TaskDocument[];
    optional: TaskDocument[];
  }>(
    (acc, file) => {
      if (typeof file.condition?.answer === 'boolean' && !!file.condition?.question) {
        acc.conditional.push(file);
        return acc;
      }
      if (file.required) {
        acc.required.push(file);
        return acc;
      }
      acc.optional.push(file);
      return acc;
    },
    { required: [], conditional: [], optional: [] },
  );

  const [conditions, setConditions] = useState<Record<string, boolean | undefined>>(
    files.reduce<Record<string, boolean | undefined>>((acc, file) => {
      if (typeof file.condition?.answer === 'boolean' && !!file.condition?.question) {
        // set initial condition state to undefined so that radio buttons are not checked at all
        acc[file.name] = undefined;
      }
      return acc;
    }, {}),
  );

  return (
    <Collapsible.Root open={open} onOpenChange={setOpen}>
      <div className="w-full rounded-lg bg-white p-4 shadow lg:p-5">
        <div className="flex flex-col space-y-4 lg:flex-row lg:items-center lg:space-x-2 lg:space-y-0">
          <div className="flex items-start space-x-2 lg:flex-1 lg:items-center">
            <span className="flex-1">
              <div className="font-medium text-gray-600">
                {name}{' '}
                <span className="text-xs text-gray-400">
                  ({id} - {kind}){' '}
                </span>
              </div>
              <div className="text-gray-400">{description}</div>
            </span>
          </div>
          <Collapsible.Trigger asChild>
            <Button>{open ? 'Collapse' : 'Expand'}</Button>
          </Collapsible.Trigger>
          {stage && (
            <Link
              variant="primary"
              to={{
                pathname: `/tasks/${task.id}`,
                state: state
                  ? { ...state, stageId: stage.id, stageName: stage.name }
                  : { stageId: stage.id, stageName: stage.name },
              }}
            >
              Go to Task Detail
            </Link>
          )}
        </div>
        <Collapsible.Content>
          <Separator decorative className="mt-8 border border-gray-200" />
          <ul>
            {Object.keys(sorted).map((key) => {
              const sortedFiles = sorted[key as keyof typeof sorted];
              return (
                <Fragment key={key}>
                  {sortedFiles.length > 0 && (
                    <>
                      {sortedFiles.map((fileConfig: any) => (
                        <File
                          key={fileConfig.name}
                          fileConfig={fileConfig}
                          isConditional={key === 'conditional'}
                          conditions={conditions}
                          setConditions={setConditions}
                        />
                      ))}
                    </>
                  )}
                </Fragment>
              );
            })}
          </ul>
        </Collapsible.Content>
      </div>
    </Collapsible.Root>
  );
}
