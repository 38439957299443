import Logo from '../../../../assets/img/supervisas-logo.png';

export function Footer() {
  return (
    <div className="mt-20 w-full py-6 text-center text-slate-800 print:hidden">
      <p className="leading-relaxed">
        The use of the <img src={Logo} alt="supervisas" className="mx-1 inline-block w-14 align-text-top" /> website is
        subject to our{' '}
        <a
          href="https://www.supervisas.com/static/local/pdf/terms-of-use-agreement.pdf"
          target="_blank"
          rel="noopener noreferrer"
          className="mx-0.5 underline"
        >
          Terms of Use
        </a>{' '}
        and{' '}
        <a
          href="https://www.supervisas.com/static/local/pdf/privacy-policy.pdf"
          target="_blank"
          rel="noopener noreferrer"
          className="mx-0.5 underline"
        >
          Privacy Policy
        </a>
        .
      </p>
      <p className="mb-4 mt-4 text-xs lg:mt-2">
        Copyright © {new Date().getFullYear()} SuperVisas, all rights reserved.
      </p>
    </div>
  );
}
