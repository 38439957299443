import { Link } from 'react-router-dom';
import { Column, FilterProps } from 'react-table';
import { SimpleStage } from '../../../models/testing/Stage';
import { DefaultColumnFilter } from '../../reviewer-dashboard/components/ui/tables/super-table/filters/DefaultColumnFilter';
import { SuperTable } from '../../reviewer-dashboard/components/ui/tables/super-table/SuperTable';
import { useProgramsQuery } from '../api/ProgramsAPI';
import { SelectColumnFilter } from '../../../shared/components/tables/super-table/filter/SelectColumnFilter';

export function ProgramsPage() {
  const { isLoading, data } = useProgramsQuery();

  const columns = [
    {
      Header: 'ID',
      accessor: 'id',
      Filter: (props: FilterProps<object>) => (
        <DefaultColumnFilter
          {...props}
          inputProps={{
            placeholder: 'Search by ID',
          }}
        />
      ),
      Cell: ({ value }: { value: number }) => (
        <Link
          className="font-mono tabular-nums text-blue-600 underline group-hover:text-brand-blue-darkest group-hover:decoration-2"
          to={`/programs/${value}`}
        >
          {value}
        </Link>
      ),
    },
    {
      Header: 'Name',
      accessor: 'name',
      filter: 'fuzzyText',
      Filter: (props: FilterProps<object>) => (
        <DefaultColumnFilter
          {...props}
          inputProps={{
            placeholder: 'Search by name',
          }}
        />
      ),
    },
    {
      Header: 'Country',
      accessor: 'country',
      Cell: ({ value }: { value: string }) => {
        return <span className="capitalize">{value}</span>;
      },
      Filter: SelectColumnFilter,
    },
    {
      Header: 'Public',
      accessor: (row: Record<string, any>) => (row.allows_new_applications ? 'Yes' : 'No'),
      Cell: ({ value }: { value: string }) => {
        return <span className="capitalize">{value}</span>;
      },
      Filter: SelectColumnFilter,
    },
  ];

  return (
    <div className="min-h-[300px] rounded-lg bg-white pb-4 shadow lg:min-h-[554px] lg:max-w-[calc(100vw-276px)]">
      <SuperTable<SimpleStage>
        data={data as any}
        columns={columns as Column<SimpleStage>[]}
        buttonWrapperClassName="px-4 bg-gray-50 rounded-t-lg"
        tableWrapperClassName="lg:px-4"
        isLoading={isLoading}
      >
        <h1 className="p-4 text-base font-semibold text-gray-500 lg:text-2xl">Programs</h1>
      </SuperTable>
    </div>
  );
}
