import { Link, useParams } from 'react-router-dom';
import { useProgramQuery } from '../api/ProgramsAPI';
import ExternalLink from '../../../shared/components/links/ExternalLink';
import { getObjectDetailUrl } from '../utils/getObjectDetailUrl';

export function ProgramDetailPage() {
  const { programId } = useParams<{ programId: string }>();
  const { data, isLoading } = useProgramQuery({ id: programId });

  if (isLoading) {
    return <div className="flex h-screen items-center justify-center text-lg">Loading...</div>;
  }

  if (!data) {
    return <div className="flex h-screen items-center justify-center text-lg">Not Found</div>;
  }

  const { stages } = data;
  return (
    <div>
      <div className="mt-3 flex justify-center lg:justify-end lg:px-14">
        <ExternalLink
          variant="primary"
          target="_blank"
          rel="noreferrer"
          to={getObjectDetailUrl('app/program', programId)}
        >
          Go to Program Detail Page in Admin
        </ExternalLink>
      </div>
      <h1 className="mb-10 mt-6 p-4 text-center text-base font-semibold text-gray-500 lg:text-2xl">{data.name}</h1>
      <div className="flex flex-col space-y-5 px-14">
        {stages.length === 0 && (
          <div className="flex w-full items-center justify-center rounded bg-gray-200 p-4 font-semibold text-gray-500 shadow-inner print:rounded-none lg:rounded-lg lg:p-8">
            no stages
          </div>
        )}

        {stages.map((stage) => {
          const { id, name, description } = stage;
          return (
            <div className="w-full rounded-lg bg-white p-4 shadow lg:p-5">
              <div className="flex flex-col space-y-4 lg:flex-row lg:items-center lg:space-x-2 lg:space-y-0">
                <div className="flex items-start space-x-2 lg:flex-1 lg:items-center">
                  <span className="flex-1">
                    <div className="font-medium text-gray-600">
                      {name} <span className="text-xs text-gray-400">({id})</span>
                    </div>
                    <div className="text-gray-400">{description}</div>
                  </span>
                </div>
                <Link
                  className="font-mono tabular-nums text-blue-600 underline group-hover:text-brand-blue-darkest group-hover:decoration-2"
                  to={{ pathname: `/stages/${id}`, state: { programId, programName: data.name } }}
                >
                  View Stage
                </Link>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}
