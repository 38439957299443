import { Alert } from '../../../shared/components/alerts/Alert';

export function HomePage() {
  return (
    <div>
      <h1 className="p-4 text-base font-semibold text-gray-500 lg:text-2xl">Home</h1>
      <Alert className="border border-gray-400">Not Yet Implemented</Alert>
    </div>
  );
}
