import { Task } from '../../../../models/UserServerData';
import { StageDetail } from '../../../../models/testing/Stage';
import { TaskCard } from './TaskCard';
import { TaskListCardFile } from './TaskListCardFile';
import { TaskListCardForm } from './TaskListCardForm';
import { TaskListCardGovernmentFeePayment } from './TaskListCardGovernmentFeePayment';
import { TaskListCardPayment } from './TaskListCardPayment';

export function TaskTestViewer({ task, stage }: { task: Task; stage?: StageDetail }) {
  switch (task.kind) {
    case 'file':
      return <TaskListCardFile task={task} stage={stage} />;
    case 'superform':
    case 'jotform':
      return <TaskListCardForm id={task.id} task={task} stage={stage} />;
    case 'payment':
      return <TaskListCardPayment id={task.id} task={task} stage={stage} />;
    case 'government_fee_payment':
      return <TaskListCardGovernmentFeePayment id={task.id} task={task} stage={stage} />;
    default:
      return <TaskCard id={task.id} task={task} stage={stage} />;
  }
}
