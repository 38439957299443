import axios, { AxiosInstance, AxiosResponse } from 'axios';
import { createContext, useContext, useEffect, useState } from 'react';
import jwtDecode from 'jwt-decode';
import { UseMutateFunction, useMutation, useQueryClient } from 'react-query';
import { isPast } from 'date-fns';
import { useHistory } from 'react-router-dom';
import { AuthAPI } from '../api/AuthAPI';
import { useLocalStorage } from '../utils/useLocalStorage';
import { AuthTokens, AuthUser } from '../../../models/Token';

interface AuthContextType {
  login: UseMutateFunction<AxiosResponse<AuthTokens>, unknown, { email: string; password: string }, unknown>;
  logout: () => void;
  authTokens: AuthTokens | null;
  setAuthTokens: (authTokens: AuthTokens) => void;
  user: AuthUser | null;
  setUser: (user: AuthUser | null) => void;
  axiosInstance: AxiosInstance;
  env: string;
}

const UNAUTHORIZED_ERROR_CODE = 401;

const AuthContext = createContext<AuthContextType>({
  login: () => undefined,
  logout: () => undefined,
  authTokens: null,
  setAuthTokens: () => undefined,
  user: null,
  setUser: () => undefined,
  axiosInstance: axios,
  env: '',
});

export const AuthProvider = ({ children, env }: { children: React.ReactChild; env: string }) => {
  const history = useHistory();
  const queryClient = useQueryClient();
  const { value: authTokens, setValue: setAuthTokens } = useLocalStorage<AuthTokens | null>('authTokens', null);
  const [user, setUser] = useState<AuthUser | null>(() => (authTokens ? jwtDecode(authTokens.access) : null));
  const [loading, setLoading] = useState(true);
  const { mutate: login } = useMutation(AuthAPI.login, {
    onSuccess: async (data) => {
      setAuthTokens(data);
      setUser(jwtDecode(data.access));
      history.push('/');
    },
  });

  const logout = () => {
    setAuthTokens(null);
    setUser(null);
    localStorage.removeItem('authTokens');
    queryClient.clear();
    history.push('/login');
  };

  useEffect(() => {
    if (authTokens) {
      setUser(jwtDecode(authTokens.access));
    }
    setLoading(false);
  }, [authTokens, loading]);

  const axiosInstance = axios.create({
    headers: { Authorization: `Bearer ${authTokens?.access}` },
  });

  axiosInstance.interceptors.request.use(async (req) => {
    if (authTokens) {
      const accessToken = jwtDecode<any>(authTokens.access);
      const isExpired = isPast(new Date(accessToken.exp * 1000));

      if (!isExpired) return req;

      const { origin, hostname } = window.location;
      const baseUrl = new URL(origin);
      baseUrl.hostname = hostname.replace('testing.', '');

      axios
        .post(`${baseUrl.origin}/api/token/refresh`, {
          refresh: authTokens.refresh,
        })
        .then((response) => {
          localStorage.setItem('authTokens', JSON.stringify(response.data));

          setAuthTokens(response.data);
          setUser(jwtDecode(response.data.access));

          req.headers.Authorization = `Bearer ${response.data.access}`;
          queryClient.clear();
        })
        .catch((error) => {
          /* logout if refresh token is expired */
          if (error?.response?.status === UNAUTHORIZED_ERROR_CODE) {
            logout();
          }
          return Promise.reject(error);
        });
    }
    return req;
  });

  return (
    <AuthContext.Provider
      value={{
        login,
        logout,
        user,
        setUser,
        authTokens,
        setAuthTokens,
        axiosInstance,
        env,
      }}
    >
      {loading ? null : children}
    </AuthContext.Provider>
  );
};

export function useAuthContext() {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuthContext must be used within an AuthProvider');
  }
  return context;
}
