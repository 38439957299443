import { useLocation } from 'react-router-dom';
import Link from '../../../../shared/components/links/Link';

export function TaskListCardGovernmentFeePayment({ task, stage }: any) {
  const { state } = useLocation();
  const { id, name, description, kind } = task;

  return (
    <div className="w-full rounded-lg bg-white p-4 shadow lg:p-5">
      <div className="flex flex-col space-y-4 lg:flex-row lg:items-center lg:space-x-2 lg:space-y-0">
        <div className="flex items-start space-x-2 lg:flex-1 lg:items-center">
          <span className="flex-1">
            <div className="font-medium text-gray-600">
              {name}{' '}
              <span className="text-xs text-gray-400">
                ({id} - {kind}){' '}
              </span>
            </div>
            <div className="text-gray-400">{description}</div>
          </span>
          {stage && (
            <Link
              variant="primary"
              to={{
                pathname: `/tasks/${task.id}`,
                state: state
                  ? { ...state, stageId: stage.id, stageName: stage.name }
                  : { stageId: stage.id, stageName: stage.name },
              }}
            >
              Go to Task Detail
            </Link>
          )}
        </div>
      </div>
    </div>
  );
}
