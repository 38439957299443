import { Link } from 'react-router-dom';
import { Column, FilterProps } from 'react-table';
import { TaskList } from '../../../models/testing/Task';
import { SimpleTask } from '../../../models/UserServerData';
import { DefaultColumnFilter } from '../../reviewer-dashboard/components/ui/tables/super-table/filters/DefaultColumnFilter';
import { SuperTable } from '../../reviewer-dashboard/components/ui/tables/super-table/SuperTable';
import { useTasksQuery } from '../api/TasksAPI';

const columns = [
  {
    Header: 'ID',
    accessor: 'id',
    Filter: (props: FilterProps<object>) => (
      <DefaultColumnFilter
        {...props}
        inputProps={{
          placeholder: 'Search by ID',
        }}
      />
    ),
    Cell: ({ value }: { value: number }) => (
      <Link
        className="font-mono tabular-nums text-blue-600 underline group-hover:text-brand-blue-darkest group-hover:decoration-2"
        to={`/tasks/${value}`}
      >
        {value}
      </Link>
    ),
  },
  {
    Header: 'Name',
    accessor: 'name',
    filter: 'fuzzyText',
    Filter: (props: FilterProps<object>) => (
      <DefaultColumnFilter
        {...props}
        inputProps={{
          placeholder: 'Search by name',
        }}
      />
    ),
  },
  {
    Header: 'Description',
    accessor: 'description',
    filter: 'fuzzyText',
    Filter: (props: FilterProps<object>) => (
      <DefaultColumnFilter
        {...props}
        inputProps={{
          placeholder: 'Search by description',
        }}
      />
    ),
  },
  {
    Header: 'Kind',
    accessor: 'kind',
    filter: 'fuzzyText',
    Filter: (props: FilterProps<object>) => (
      <DefaultColumnFilter
        {...props}
        inputProps={{
          placeholder: 'Search by kind',
        }}
      />
    ),
  },
];

export function TasksPage() {
  const { isLoading, data } = useTasksQuery();
  return (
    <div className="min-h-[300px] rounded-lg bg-white pb-4 shadow lg:min-h-[554px] lg:max-w-[calc(100vw-276px)]">
      <SuperTable<SimpleTask>
        data={data as TaskList}
        columns={columns as Column<SimpleTask>[]}
        buttonWrapperClassName="px-4 bg-gray-50 rounded-t-lg"
        tableWrapperClassName="lg:px-4"
        isLoading={isLoading}
      >
        <h1 className="p-4 text-base font-semibold text-gray-500 lg:text-2xl">Tasks</h1>
      </SuperTable>
    </div>
  );
}
