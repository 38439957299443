import { useQuery } from 'react-query';
import { StageDetail, StageList } from '../../../models/testing/Stage';

import { useAuthContext } from '../contexts/AuthContext';

export const useStagesQuery = () => {
  const { axiosInstance } = useAuthContext();
  return useQuery<StageList, Error>(['stages'], async () => {
    const res = await axiosInstance.get(`/api/stages`);
    return res.data;
  });
};

export const useStageQuery = ({ id }: { id: string }) => {
  const { axiosInstance } = useAuthContext();
  return useQuery<StageDetail, Error>(['stages', id], async () => {
    const res = await axiosInstance.get(`/api/stages/${id}`);
    return res.data;
  });
};
