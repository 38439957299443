import { NavHeader } from './components/NavHeader';
import { SideNavbar } from './components/SideNavbar';

export function Navbar() {
  return (
    <>
      <div className="h-14 lg:h-auto">
        <NavHeader />
      </div>
      <SideNavbar />
    </>
  );
}
