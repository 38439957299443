import { ReactNode } from 'react';
import { NavLink, useRouteMatch } from 'react-router-dom';
import cn from 'classnames';
import * as AlertDialog from '@radix-ui/react-alert-dialog';
import { IconDescription, IconHome, IconLogout } from '../../../../../../shared/components/icons';
import Button from '../../../../../../shared/components/buttons/Button';
import { useAuthContext } from '../../../../contexts/AuthContext';

interface NavItemProps {
  to: string;
  icon: React.ReactNode;
  children: ReactNode;
  exact?: boolean;
}

function NavItem({ to, icon, children, exact = false }: NavItemProps) {
  const match = useRouteMatch({
    path: to,
    strict: true,
    exact,
  });
  return (
    <li className={cn('min-w-[182px] lg:rounded-lg', match ? 'bg-brand-blue-light' : 'hover:bg-gray-100')}>
      <NavLink
        to={to}
        className={cn(
          'flex items-center space-x-2 px-4 py-1 lg:py-3',
          match ? 'text-brand-blue hover:text-brand-blue-dark' : 'text-gray-600 hover:text-gray-700',
        )}
      >
        <span>{icon}</span>
        <span className="text-sm font-medium">{children}</span>
      </NavLink>
    </li>
  );
}

function Divider() {
  return <hr className="my-3 border-gray-300" />;
}

export function NavItems() {
  const { logout } = useAuthContext();
  return (
    <>
      <NavItem to="/" icon={<IconHome className="h-5 w-5" />} exact>
        Home
      </NavItem>
      <NavItem to="/programs" icon={<IconDescription className="h-5 w-5" />}>
        Programs
      </NavItem>
      <NavItem to="/stages" icon={<IconDescription className="h-5 w-5" />}>
        Stages
      </NavItem>
      <NavItem to="/tasks" icon={<IconDescription className="h-5 w-5" />}>
        Tasks
      </NavItem>
      <NavItem to="/superforms" icon={<IconDescription className="h-5 w-5" />}>
        SuperForms
      </NavItem>
      <Divider />
      <AlertDialog.Root>
        <AlertDialog.Trigger>
          <li className="min-w-[182px] rounded-lg hover:bg-gray-100">
            <div className="flex items-center space-x-2 px-4 py-1 text-gray-600 hover:text-gray-600 lg:py-3">
              <IconLogout className="h-5 w-5" />
              <span className="text-sm font-medium">Logout</span>
            </div>
          </li>
        </AlertDialog.Trigger>
        <AlertDialog.Overlay className="fixed inset-0 z-40 bg-gray-800 opacity-80" />
        <AlertDialog.Content className="fixed left-1/2 top-1/2 z-50 max-h-[85vh] w-[90vw] max-w-[500px] -translate-x-1/2 -translate-y-1/2 rounded-md bg-white p-6 shadow-md focus:outline-none">
          <AlertDialog.Title className="text-lg font-medium text-gray-700">Do you want to log out?</AlertDialog.Title>
          <AlertDialog.Description className="mb-5 text-sm leading-normal text-gray-600">
            You can always access your content by logging in again.
          </AlertDialog.Description>
          <div className="flex justify-end space-x-6">
            <AlertDialog.Cancel asChild>
              <Button variant="secondary" className="flex-1 lg:flex-none">
                Cancel
              </Button>
            </AlertDialog.Cancel>
            <AlertDialog.Action asChild>
              <Button className="flex-1 lg:flex-none" onClick={logout}>
                Logout
              </Button>
            </AlertDialog.Action>
          </div>
        </AlertDialog.Content>
      </AlertDialog.Root>
    </>
  );
}
