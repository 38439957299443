interface GeneralErrorProps {
  error: Error;
  componentStack: string | null;
}

export function GeneralError({ error, componentStack }: GeneralErrorProps) {
  return (
    <div className="p-10">
      <h1 className="mb-6 text-center text-lg font-semibold text-gray-800">Error</h1>
      <div className="font-medium text-pink-600">{error.toString()}</div>
      <code className="text-gray-500">{componentStack}</code>
    </div>
  );
}
