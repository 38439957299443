import { useRef } from 'react';
import Button from '../../../shared/components/buttons/Button';
import { useAuthContext } from '../contexts/AuthContext';

export function LoginPage() {
  const formRef = useRef(null);
  const { login } = useAuthContext();

  async function onSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    if (formRef?.current) {
      const data = new FormData(formRef.current);
      login({
        email: data.get('email') as string,
        password: data.get('password') as string,
      });
    }
  }
  return (
    <div>
      <h1 className="mb-20 mt-20 pr-4 text-center text-2xl font-bold text-gray-600 lg:mt-40 lg:text-3xl">
        Testing Portal Login
      </h1>
      <div className="flex items-center justify-center">
        <form ref={formRef} className="mx-auto min-w-[250px]" onSubmit={onSubmit}>
          <div>
            <label htmlFor="email" className="text-gray-600">
              Email
            </label>
            <input
              type="email"
              id="email"
              name="email"
              className="block w-full rounded border-gray-400 text-gray-700"
              required
            />
          </div>
          <div className="mb-10 mt-6 lg:mb-14">
            <label htmlFor="password" className="text-gray-600">
              Password
            </label>
            <input
              type="password"
              id="password"
              name="password"
              className="block w-full rounded border-gray-400 text-gray-700"
              required
            />
          </div>
          <div>
            <Button className="w-full" type="submit">
              Login
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
}
