import { useQuery } from 'react-query';
import { SuperFormDetail, SuperFormList } from '../../../models/testing/SuperForm';
import { useAuthContext } from '../contexts/AuthContext';

export const useSuperFormsQuery = () => {
  const { axiosInstance } = useAuthContext();
  return useQuery<SuperFormList, Error>(['superforms'], async () => {
    const res = await axiosInstance.get(`/api/superforms`);
    return res.data;
  });
};

export const useSuperFormQuery = ({ id }: { id: string }) => {
  const { axiosInstance } = useAuthContext();
  return useQuery<SuperFormDetail, Error>(['superforms', id], async () => {
    const res = await axiosInstance.get(`/api/superforms/${id}`);
    return res.data;
  });
};
