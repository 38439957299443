import { useQuery } from 'react-query';
import { ProgramList } from '../../../models/testing/Program';
import { Program } from '../../../models/UserServerData';
import { useAuthContext } from '../contexts/AuthContext';

export const useProgramsQuery = () => {
  const { axiosInstance } = useAuthContext();
  return useQuery<ProgramList, Error>(['programs'], async () => {
    const res = await axiosInstance.get(`/api/programs`);
    return res.data;
  });
};

export const useProgramQuery = ({ id }: { id: string }) => {
  const { axiosInstance } = useAuthContext();
  return useQuery<Program, Error>(['programs', id], async () => {
    const res = await axiosInstance.get(`/api/programs/${id}`);
    return res.data;
  });
};
