import * as Collapsible from '@radix-ui/react-collapsible';
import { useState } from 'react';
import { useLocation } from 'react-router-dom';
import Button from '../../../../shared/components/buttons/Button';
import { SuperFormTestViewer } from '../superform/SuperFormTestViewer';
import Link from '../../../../shared/components/links/Link';

export function TaskListCardForm({ task, stage }: any) {
  const { state } = useLocation();
  const { id, name, description, kind, config } = task;
  const [open, setOpen] = useState(false);

  return (
    <Collapsible.Root open={open} onOpenChange={setOpen}>
      <div className="w-full rounded-lg bg-white p-4 shadow lg:p-5">
        <div className="flex flex-col space-y-4 lg:flex-row lg:items-center lg:space-x-2 lg:space-y-0">
          <div className="flex items-start space-x-2 lg:flex-1 lg:items-center">
            <span className="flex-1">
              <div className="font-medium text-gray-600">
                {name}{' '}
                <span className="text-xs text-gray-400">
                  ({id} - {kind})
                </span>
              </div>
              <div className="text-gray-400">{description}</div>
            </span>
          </div>
          <Collapsible.Trigger asChild>
            <Button>{open ? 'Collapse' : 'Expand'}</Button>
          </Collapsible.Trigger>
          {stage && (
            <Link
              variant="primary"
              to={{
                pathname: `/tasks/${task.id}`,
                state: state
                  ? { ...state, stageId: stage.id, stageName: stage.name }
                  : { stageId: stage.id, stageName: stage.name },
              }}
            >
              Go to Task Detail
            </Link>
          )}
        </div>
        <Collapsible.Content>
          {kind === 'superform' && <SuperFormTestViewer superFormId={config.superform_id} />}
          {kind === 'jotform' && <div className="mt-4 text-gray-600">See jotform id {config.jotform_id}</div>}
        </Collapsible.Content>
      </div>
    </Collapsible.Root>
  );
}
