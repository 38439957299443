import { useContext } from 'react';
import cn from 'classnames';
import { NavMenuContext } from '../../../../../../shared/utils/menu';
import { IconClose, IconMenu, IconNotifications } from '../../../../../../shared/components/icons';
import { NavItems } from './NavItems';
import Logo from '../../../../../assets/img/supervisas-logo.png';
import { useNotificationContext } from '../../../../../../shared/contexts/NotificationContext';

export function NavHeader() {
  const { isOpen, toggleNavMenu, navMenuRef } = useContext(NavMenuContext);
  const { hasUnreadNotifications, toggleSideBar, showSidebar } = useNotificationContext();
  return (
    <div
      className={cn('w-screen bg-white print:hidden lg:hidden', { 'absolute top-0 z-[9999999999]': isOpen })}
      ref={navMenuRef}
    >
      <div className="flex items-center justify-between">
        <div className="flex items-center space-x-4 p-2 print:space-x-0 print:px-4 print:py-0">
          <button type="button" onClick={toggleNavMenu} className="rounded-full p-2 hover:bg-gray-200 print:hidden">
            {isOpen ? <IconClose /> : <IconMenu />}
          </button>
          <img src={Logo} alt="SuperVisas' logo" className={cn('max-h-[24px]', { invisible: isOpen })} />
        </div>
        <div className={cn('print:hidden lg:hidden', { hidden: isOpen })}>
          <button
            className="relative m-2 rounded-full p-2 hover:bg-gray-200"
            type="button"
            onClick={() => toggleSideBar(showSidebar === null ? 'GENERAL' : null)}
          >
            <IconNotifications className="h-6 w-6" />
            {hasUnreadNotifications && (
              <span className="absolute right-[10px] top-[22px] inline-block h-2.5 w-2.5 rounded-full bg-red-700" />
            )}
          </button>
        </div>
      </div>
      {isOpen && (
        <nav className="h-[calc(100vh-56px)]">
          <ul>
            <NavItems />
          </ul>
        </nav>
      )}
    </div>
  );
}
