import axios from 'axios';

const { origin, hostname } = window.location;
const baseUrl = new URL(origin);
baseUrl.hostname = hostname.replace('testing.', '');

const apiClient = axios.create({
  baseURL: baseUrl.origin,
});

const login = async (body: { email: string; password: string }) => {
  const res = await apiClient.post('/api/token', body);
  return res.data;
};

export const AuthAPI = {
  login,
};
