import * as Sentry from '@sentry/react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { Router, Switch, Route, Redirect } from 'react-router-dom';
import { History } from 'history';
import * as ToastPrimitive from '@radix-ui/react-toast';
import { HomePage } from './pages/HomePage';
import { LoginPage } from './pages/LoginPage';
import { Navbar } from './components/ui/nav-bars/Navbar';
import { Footer } from './components/ui/footers/Footer';
import { NavMenuProvider } from '../../shared/utils/menu';
import { AuthProvider, useAuthContext } from './contexts/AuthContext';
import { StagesPage } from './pages/StagesPage';
import { GeneralError } from './components/ui/errors/GeneralError';
import { TasksPage } from './pages/TasksPage';
import { ProgramDetailPage } from './pages/ProgramDetailPage';
import { SuperFormsPage } from './pages/SuperFormsPage';
import { ProgramsPage } from './pages/ProgramsPage';
import { StageDetailPage } from './pages/StageDetailPage';
import { TaskDetailPage } from './pages/TaskDetailPage';
import { SuperFormDetailPage } from './pages/SuperFormDetailPage';

const SentryRoute = Sentry.withSentryRouting(Route);

const globalQueryClient = new QueryClient({
  defaultOptions: {
    queries: {
      cacheTime: 60 * 60 * 1000,
    },
  },
});

function RoutesWithNavbar() {
  const { user } = useAuthContext();
  if (!user || !user.groups.some((group) => ['testers'].includes(group))) {
    return <Redirect to="/login" />;
  }
  return (
    <div className="flex w-screen flex-col font-sans text-sm text-gray-900">
      <div className="flex min-h-screen flex-col lg:flex-row">
        <Navbar />
        <div className="flex-1 overflow-x-scroll !bg-gray-100 p-4 print:bg-white print:py-0">
          <div className="min-h-[calc(100vh-173px)] lg:min-h-[calc(100vh-160px)]">
            <SentryRoute path="/" exact>
              <HomePage />
            </SentryRoute>
            <SentryRoute path="/programs" exact>
              <ProgramsPage />
            </SentryRoute>
            <SentryRoute path="/programs/:programId" exact>
              <ProgramDetailPage />
            </SentryRoute>
            <SentryRoute path="/stages" exact>
              <StagesPage />
            </SentryRoute>
            <SentryRoute path="/stages/:stageId" exact>
              <StageDetailPage />
            </SentryRoute>
            <SentryRoute path="/tasks" exact>
              <TasksPage />
            </SentryRoute>
            <SentryRoute path="/tasks/:taskId" exact>
              <TaskDetailPage />
            </SentryRoute>
            <SentryRoute path="/superforms" exact>
              <SuperFormsPage />
            </SentryRoute>
            <SentryRoute path="/superforms/:superFormId" exact>
              <SuperFormDetailPage />
            </SentryRoute>
          </div>
          <Footer />
        </div>
      </div>
    </div>
  );
}

export function App({ history, env }: { history: History; env: string }) {
  return (
    <Sentry.ErrorBoundary fallback={GeneralError}>
      <QueryClientProvider client={globalQueryClient}>
        <ToastPrimitive.Provider>
          <ToastPrimitive.Viewport />
          <Router history={history}>
            <NavMenuProvider>
              <AuthProvider env={env}>
                <Switch>
                  <SentryRoute path="/login" exact>
                    <LoginPage />
                  </SentryRoute>
                  <SentryRoute component={RoutesWithNavbar} />
                </Switch>
              </AuthProvider>
            </NavMenuProvider>
          </Router>
        </ToastPrimitive.Provider>
      </QueryClientProvider>
    </Sentry.ErrorBoundary>
  );
}
