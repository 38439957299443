import { useTranslation } from 'react-i18next';
import { Link, useLocation, useParams } from 'react-router-dom';
import { NS } from '../../../models/I18n';
import { useStageQuery } from '../api/StagesAPI';
import { TaskTestViewer } from '../components/task/TaskTestViewer';
import ExternalLink from '../../../shared/components/links/ExternalLink';
import { getObjectDetailUrl } from '../utils/getObjectDetailUrl';

export function StageDetailPage() {
  const { state } = useLocation<{ programId: string; programName: string }>();
  const showBreadcrumb = !!(state?.programId && state?.programName);
  const { t } = useTranslation(NS.UserDashboard);
  const { stageId } = useParams<{ stageId: string }>();
  const { data, isLoading } = useStageQuery({ id: stageId });

  if (isLoading) {
    return <div className="flex h-screen items-center justify-center text-lg">Loading...</div>;
  }

  if (!data) {
    return <div className="flex h-screen items-center justify-center text-lg">Not Found</div>;
  }

  const { tasks } = data;
  return (
    <div>
      {showBreadcrumb && (
        <div className="my-10 px-14 print:hidden">
          <Link to={`/programs/${state.programId}`}>{state.programName}</Link>
          <div className="pointer-events-none mx-2 inline-flex select-none items-center font-medium text-gray-400">
            /
          </div>
          <span className="inline-flex items-center font-medium text-gray-400">{data.name}</span>
        </div>
      )}
      <div className="mt-3 flex justify-center lg:justify-end lg:px-14">
        <ExternalLink variant="primary" target="_blank" rel="noreferrer" to={getObjectDetailUrl('app/stage', stageId)}>
          Go to Stage Detail Page in Admin
        </ExternalLink>
      </div>
      <h1 className="mb-10 mt-6 p-4 text-center text-base font-semibold text-gray-500 lg:text-2xl">{data.name}</h1>
      <div className="flex flex-col space-y-5 px-14">
        {tasks.length === 0 && (
          <div className="flex w-full items-center justify-center rounded bg-gray-200 p-4 font-semibold text-gray-500 shadow-inner print:rounded-none lg:rounded-lg lg:p-8">
            {t('taskList.no-tasks')}
          </div>
        )}

        {tasks.map((task) => (
          <TaskTestViewer task={task} stage={data} />
        ))}
      </div>
    </div>
  );
}
