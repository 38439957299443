import { NavItems } from './NavItems';
import Logo from '../../../../../assets/img/supervisas-logo.png';

export function SideNavbar() {
  return (
    <div className="hidden min-h-screen flex-col bg-white px-6 shadow lg:flex">
      <img src={Logo} alt={`SuperVisas' logo`} className="mx-auto mb-10 mt-12 max-w-[130px]" />
      <nav className="pb-10">
        <ul className="mb-0 flex flex-col">
          <NavItems />
        </ul>
      </nav>
    </div>
  );
}
