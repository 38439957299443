import { useRef } from 'react';
import SuperForm from '../../../../shared/components/forms/SuperForm';
import { useSuperFormQuery } from '../../api/SuperFormsAPI';
import ExternalLink from '../../../../shared/components/links/ExternalLink';
import { getObjectDetailUrl } from '../../utils/getObjectDetailUrl';

export function SuperFormTestViewer({ superFormId }: { superFormId: string }) {
  const { data, isLoading } = useSuperFormQuery({ id: superFormId });
  const formDataRef = useRef({});
  const handleSubmit = (superFormData: any) => {
    // log superform data. Do not submit test superforms to backend
    console.log(superFormData); // eslint-disable-line no-console
  };

  if (isLoading) {
    return <div className="flex h-screen items-center justify-center text-lg">Loading...</div>;
  }

  if (!data) {
    return <div className="flex h-screen items-center justify-center text-lg">Not Found</div>;
  }

  const {
    definitions,
    ui_schema: uiSchema,
    initial_data: initialData,
    rules,
    sections,
    section_conditions: sectionConditions,
  } = data;
  const schemas = data.sections.map((section: any) => {
    return { ...section, definitions };
  });

  return (
    <div>
      <div className="mt-3 flex justify-center lg:justify-end lg:px-14">
        <ExternalLink
          variant="primary"
          target="_blank"
          rel="noreferrer"
          to={getObjectDetailUrl('superform/superform', superFormId)}
        >
          Go to SuperForm Detail Page in Admin
        </ExternalLink>
      </div>
      <h1 className="mb-10 mt-6 p-4 text-center text-base font-semibold text-gray-500 lg:text-2xl">{data.name}</h1>
      <SuperForm
        initialFormData={initialData}
        uiSchema={uiSchema}
        rules={rules}
        schemas={schemas}
        sections={sections}
        conditions={sectionConditions}
        handleSubmit={handleSubmit}
        formDataRef={formDataRef}
      />
    </div>
  );
}
