import * as Sentry from '@sentry/react';

import { createRoot } from 'react-dom/client';
import { StrictMode } from 'react';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { format as formatDate, isDate, formatDistance, formatRelative, Locale } from 'date-fns';
import { enCA, es, zhCN, ko } from 'date-fns/locale';
import { createBrowserHistory } from 'history';
import { App } from './testing-dashboard';
import { userDashboardResources } from '../i18n/resources';
import { NS } from '../models/I18n';
import './testing.css';

const history = createBrowserHistory();

const serverData = JSON.parse(document.getElementById('server-data')?.textContent || '{}');

Sentry.init({
  dsn: process.env.SENTRY_TESTING_DSN,
  environment: serverData.env.SENTRY_ENV,
  release: serverData.env.RELEASE,
  integrations: [
    new Sentry.BrowserTracing({
      tracingOrigins: [window.location.host, /^\//],
      routingInstrumentation: Sentry.reactRouterV5Instrumentation(history),
    }),
    new Sentry.Replay(),
  ],
  tracesSampleRate: 0.1,
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});

const locales: { [key: string]: Locale } = { en: enCA, es, zh: zhCN, ko };

i18n
  .use(LanguageDetector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    debug: false,
    fallbackLng: 'en',
    ns: [NS.UserDashboard, NS.Eligibility, NS.Shared],
    defaultNS: NS.UserDashboard,
    interpolation: {
      escapeValue: false, // react already safes from xss,
      format: (value, format, lng, ...additional) => {
        if (isDate(value) && lng && format) {
          const locale = locales[lng];

          if (format === 'numeric') {
            return formatDate(value, 'P', { locale });
          }
          if (format === 'short') {
            return formatDate(value, lng === 'ko' || lng === 'zh' ? 'PPP' : 'PP', { locale });
          }
          if (format === 'long') {
            return formatDate(value, 'PPPP', { locale });
          }
          if (format === 'datetime') {
            return formatDate(value, 'PPpp', { locale });
          }
          if (format === 'relative') {
            return formatRelative(value, new Date(), { locale });
          }
          if (format === 'ago') {
            return formatDistance(value, new Date(), {
              locale,
              addSuffix: true,
            });
          }

          return formatDate(value, format, { locale });
        }
        if (!Number.isNaN(value)) {
          if (format === 'currency') {
            if (additional[0]?.lng) {
              return Intl.NumberFormat(additional[0]?.lng, {
                style: 'currency',
                currency: additional[0]?.currency || 'CAD',
              }).format(value);
            }
            return Intl.NumberFormat(lng, {
              style: 'currency',
              currency: additional[0]?.currency || 'CAD',
            }).format(value);
          }
          if (format === 'currency-no-fraction') {
            if (additional[0]?.lng) {
              return Intl.NumberFormat(additional[0]?.lng, {
                style: 'currency',
                currency: additional[0]?.currency || 'CAD',
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
              }).format(value);
            }
            return Intl.NumberFormat(lng, {
              style: 'currency',
              currency: additional[0]?.currency || 'CAD',
              minimumFractionDigits: 0,
              maximumFractionDigits: 0,
            }).format(value);
          }
        }
        return value;
      },
    },
    resources: userDashboardResources,
  });

createRoot(document.getElementById('root')!).render(
  <StrictMode>
    <App history={history} env={serverData.env.SENTRY_ENV} />
  </StrictMode>,
);
