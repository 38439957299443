import { Link, useLocation, useParams } from 'react-router-dom';
import { useTaskQuery } from '../api/TasksAPI';
import { TaskTestViewer } from '../components/task/TaskTestViewer';
import ExternalLink from '../../../shared/components/links/ExternalLink';
import { getObjectDetailUrl } from '../utils/getObjectDetailUrl';

export function TaskDetailPage() {
  const { state } = useLocation<{ programId: string; programName: string; stageId: number; stageName: string }>();
  const showProgram = !!(state?.programId && state?.programName);
  const showStage = !!(state?.stageId && state?.stageName);

  const { taskId } = useParams<{ taskId: string }>();
  const { data, isLoading } = useTaskQuery({ id: taskId });

  if (isLoading) {
    return <div className="flex h-screen items-center justify-center text-lg">Loading...</div>;
  }

  if (!data) {
    return <div className="flex h-screen items-center justify-center text-lg">Not Found</div>;
  }

  return (
    <>
      {showStage && (
        <div className="my-10 px-14 print:hidden">
          {/* when the task detail is redirected from stage without going to program first, this won't show */}
          {showProgram && (
            <>
              <Link to={`/programs/${state.programId}`}>{state.programName}</Link>
              <div className="pointer-events-none mx-2 inline-flex select-none items-center font-medium text-gray-400">
                /
              </div>
            </>
          )}
          <Link
            to={{
              pathname: `/stages/${state.stageId}`,
              state: {
                programId: state?.programId,
                programName: state?.programName,
                stageName: state.stageName,
                stageId: state.stageId,
              },
            }}
          >
            {state.stageName}
          </Link>
          <div className="pointer-events-none mx-2 inline-flex select-none items-center font-medium text-gray-400">
            /
          </div>
          <span className="inline-flex items-center font-medium text-gray-400">{data.name}</span>
        </div>
      )}
      <div className="mt-3 flex justify-center lg:justify-end lg:px-14">
        <ExternalLink variant="primary" target="_blank" rel="noreferrer" to={getObjectDetailUrl('app/task', taskId)}>
          Go to Task Detail Page in Admin
        </ExternalLink>
      </div>
      <h1 className="mb-10 mt-6 p-4 text-center text-base font-semibold text-gray-500 lg:text-2xl">Task: {taskId}</h1>
      <div className="mt-12 flex flex-col space-y-5 px-14">
        <TaskTestViewer task={data} />
      </div>
    </>
  );
}
