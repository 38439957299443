import { useQuery } from 'react-query';
import { Task } from '../../../models/UserServerData';

import { useAuthContext } from '../contexts/AuthContext';

export const useTasksQuery = () => {
  const { axiosInstance } = useAuthContext();
  return useQuery<Task[], Error>(['tasks'], async () => {
    const res = await axiosInstance.get(`/api/tasks`);
    return res.data;
  });
};

export const useTaskQuery = ({ id }: { id: string }) => {
  const { axiosInstance } = useAuthContext();
  return useQuery<Task, Error>(['tasks', id], async () => {
    const res = await axiosInstance.get(`/api/tasks/${id}`);
    return res.data;
  });
};
